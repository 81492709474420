/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable complexity */
import { format, isValid, parseISO } from "date-fns"
import { fr } from "date-fns/locale"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Chip } from "@/components/ui-custom/chip"
import { DashboardTask } from "@/services/SupabaseTravoApi"
import { Checkbox } from "@/components/ui/checkbox"
import { CustomIcon } from "@/components/ui-custom/custom-icon"
import { CloseButton } from "@/app/[lang]/app/(task)/(components)/CloseButton"
import { MoreVertical, Pencil } from "lucide-react"
import React, { useCallback } from "react"
import clsx from "clsx"
import {
  SheetBodyTaskItemWithAssignations,
  SheetBodyTaskItemWithTeamsServices,
  showPageSheetPanel,
} from "@/components/ui-custom/dialogs/SheetPanel"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card"
import { useCommonClientHooks } from "@/utils/custom-hooks"
import { showPageDialog } from "@/components/ui-custom/dialogs/page-dialog"
import { convertTaskIntoTemplate } from "@/services/task/convertTaskIntoTemplate"
import { toast } from "@/components/ui/use-toast"
import { deleteTasks } from "@/services/task/deleteTask"

// Helper function for safe date formatting
const formatDate = (dateString: string, formatStr: string): string => {
  try {
    const date = parseISO(dateString)
    if (!isValid(date)) {
      throw new Error("Invalid date")
    }
    return format(date, formatStr, { locale: fr })
  } catch (error) {
    console.error(`Error formatting date: ${dateString}`, error)
    return "Invalid date"
  }
}

interface TaskListRowProps {
  task: DashboardTask
  isChecked: boolean
  onCheckedChange: (value: boolean) => void
}

export function TaskListRow({ task, isChecked, onCheckedChange }: TaskListRowProps) {
  const { routeTo } = useCommonClientHooks()

  const handleRowClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement
      if (target.getAttribute("aria-label") === "Select row") {
        return
      }

      showPageSheetPanel({
        onClose: () => {},
        type: "task",
        data: task,
      })
    },
    [task],
  )

  const isPlanned = task.taskPlannedDates?.length > 0
  const isClosed = task.status === "closed"
  const isFromRequest = task.fromRequestData !== null

  return (
    <div className="task-list-row flex border border-r-3 hover:border-forecast-blue-light bg-[#F4F4F5] border-[#D4D4D8] rounded-sm h-[91px] overflow-hidden">
      <div className="task-list-row-left flex-1 cursor-pointer" onClick={handleRowClick}>
        <div className="task-list-row-left-numbertitle bg-white h-[39px] flex items-center pr-4">
          <div className="task-list-row-left-numbertitle-idtitle ml-6  flex items-center gap-4">
            <Checkbox
              checked={isChecked}
              onCheckedChange={onCheckedChange}
              aria-label="Select row"
            />
            <span className="text-500-14 w-[110px]">{task.taskNumber}</span>
            <div className="flex-1 flex items-center text-500-18  !line-clamp-1">
              {task.title}
            </div>
          </div>
          <div className="flex-1" />
          <div className="task-list-row-left-numbertitle-badges shrink-0 ml-6">
            {task.parentId && (
              <Chip isSubtask v2 label={`Sous-tâche de ${task.parent?.taskNumber}`} />
            )}
            {task.subtasks && task.subtasks.length > 0 && (
              <Chip
                hasSubtask
                v2
                label={`${task.subtasks.length} Sous-tâche${task.subtasks.length > 1 ? "s" : ""}`}
              />
            )}
          </div>
        </div>
        <div className="task-list-row-left-statuses h-[52px] flex items-center gap-6">
          <div className="w-[calc(110px+16px+16px-8px)] ml-6 flex justify-center">
            <Chip v2 priority={task.priority} />
          </div>
          <div className="flex items-center gap-6">
            {task.fromRequestData ? (
              <TaskListRowBottomBlock
                width={128}
                label="Demandée le"
                value={
                  isFromRequest
                    ? formatDate(task.fromRequestData.createdAt, "EEE dd MMM yyyy")
                    : "N/A"
                }
                valueDisabled={!isFromRequest}
              />
            ) : (
              <div className="w-[128px]" />
            )}
            <TaskListRowBottomBlock
              width={128}
              label="Créée le"
              value={formatDate(task.createdAt, "EEE dd MMM yyyy")}
            />
            <TaskListRowBottomBlock
              width={128}
              label="Planifiée le"
              value={
                isPlanned && task.taskPlannedDates[0]
                  ? formatDate(task.taskPlannedDates[0].plannedDate, "EEE dd MMM yyyy")
                  : "non planifiée"
              }
              valueDisabled={!isPlanned}
            />
            <TaskListRowBottomBlock
              label="Statut"
              value={isClosed ? "Clôturée" : "Ouverte"}
              iconBefore={
                <CustomIcon
                  type={isClosed ? "Icon-satus-closed" : "Icon-satus-open"}
                  className="h-4 w-4"
                />
              }
              borderLeft
            />
          </div>
          <div className="flex-1" />
          <TaskBlock task={task} />
        </div>
      </div>

      <div className="task-list-row-right w-6 border-l border-[#E4E4E7] cursor-pointer">
        <DropdownMenuTask
          task={task}
          items={[
            {
              label: "Modifier",
              icon: <Pencil className="h-4 w-4" />,
              onClick: () => {
                if (task.parentId) {
                  routeTo("task", {
                    task: { id: task.id, parentId: task.parentId },
                  })
                } else {
                  routeTo("task", { task: { id: task.id } })
                }
              },
            },
          ]}
        />
      </div>
    </div>
  )
}

interface TaskBlockProps {
  task: DashboardTask
}

export function TaskBlock({ task }: TaskBlockProps) {
  let assignationText = "Assignée à "
  if (task.services.length > 0) {
    assignationText += `${task.services.length} service${task.services.length > 1 ? "s" : ""}`
  }
  if (task.services.length > 0 && task.teams.length > 0) {
    assignationText += ", "
  } else if (task.services.length > 0 && task.assignations.length > 0) {
    assignationText += ", "
  }
  if (task.teams.length > 0) {
    assignationText += `${task.teams.length} équipe${task.teams.length > 1 ? "s" : ""}`
  }
  if (task.teams.length > 0 && task.assignations.length > 0) {
    assignationText += ", "
  }
  if (task.assignations.length > 0) {
    assignationText += `${task.assignations.length} travailleur${task.assignations.length > 1 ? "s" : ""}`
  }

  if (task.assignations.length > 0 || task.teams.length > 0 || task.services.length > 0) {
    return (
      <HoverCard>
        <HoverCardTrigger>
          <div className="mr-4">
            <div className="flex items-center gap-1">
              <CustomIcon
                type="Icon-check-done-success"
                className="h-4 w-4 text-success-text"
              />
              <span className="text-400-14">{assignationText}</span>
            </div>
          </div>
        </HoverCardTrigger>
        <HoverCardContent side="left" className="w-[400px]">
          <div className="flex flex-col gap-4">
            <SheetBodyTaskItemWithTeamsServices minimalSpace task={task} />
            <SheetBodyTaskItemWithAssignations minimalSpace task={task} />
          </div>
        </HoverCardContent>
      </HoverCard>
    )
  } else {
    return (
      <div className="mr-4">
        <div className="flex items-center gap-1">
          <CustomIcon type="Icon-x-circle" />
          <span className="text-400-14 text-black-dark">Non assignée</span>
        </div>
      </div>
    )
  }
}

interface DropdownMenuTaskProps {
  task: DashboardTask
  items?: Array<{
    label: string
    icon: React.ReactNode
    onClick: () => void
  }>
  middle?: boolean
}

export function DropdownMenuTask({ task, items = [], middle }: DropdownMenuTaskProps) {
  const { router, routeTo } = useCommonClientHooks()

  const onConvertTaskIntoTemplate = async (taskId: string) => {
    showPageDialog({
      isOpen: true,
      title: "Convertir la tâche en modèle de tâche ?",
      mainMessage: (
        <div>
          La tâche va être dupliquée et convertie en modèle.
          <br />
          <br />
          Tous les contenus sont dupliqués à part les assignations de personnes et les
          sous-tâches.
        </div>
      ),
      onClose: () => {},
      onConfirmText: "Convertir",
      onCloseText: "Annuler",
      type: "normal",
      onConfirm: () => onConfirmationConvertTaskIntoTemplate(taskId),
    })
  }

  const onConfirmationConvertTaskIntoTemplate = async (taskId: string) => {
    const { success, id, message } = await convertTaskIntoTemplate(taskId)
    if (success && id) {
      routeTo("task", { task: { id } })
    } else {
      console.error("error : ", message)
    }
  }

  const onDeleteSelectedTasks = async (taskId: string) => {
    showPageDialog({
      isOpen: true,
      title: "Supprimer la tâche ?",
      mainMessage: `Vous êtes sur le point de supprimer la tâche.
  La suppression est définitive et vous ne pourrez plus la récupérer.`,
      onClose: () => {},
      onConfirmText: "Supprimer",
      onCloseText: "Annuler",
      type: "normal",
      onConfirm: () => onConfirmationDeleteSelectedTasks([taskId]),
    })
  }

  const onConfirmationDeleteSelectedTasks = async (idList: string[]) => {
    const resultat = await deleteTasks(idList)
    if (resultat.error) {
      console.error("onConfirmationDeleteSelectedTasks error", resultat.error)
      toast({
        description: "Une erreur est survenue durant la suppression",
      })
    } else {
      const description =
        idList.length > 1 ? "Les tâches ont été supprimées" : "La tâche a été supprimée"

      toast({
        description,
      })
      router.refresh()
    }
  }

  return (
    <DropdownMenu>
      {middle ? (
        <DropdownMenuTrigger className="">
          <div className="">
            <MoreVertical className="" />
          </div>
        </DropdownMenuTrigger>
      ) : (
        <DropdownMenuTrigger className="h-full w-full">
          <div className="h-full w-full">
            <MoreVertical className="mt-2" />
          </div>
        </DropdownMenuTrigger>
      )}
      <DropdownMenuContent side="left" align="start">
        {items.map((item) => {
          return (
            <DropdownMenuItem key={item.label} onClick={item.onClick}>
              <div className="flex items-center gap-1.5">
                {item.icon}
                <span className="text-500-14">{item.label}</span>
              </div>
            </DropdownMenuItem>
          )
        })}

        <CloseButton task={task} mode="DropdownMenuItem" />

        {task.parentId === null && (
          <DropdownMenuItem onClick={() => onConvertTaskIntoTemplate(task.id)}>
            <div className="flex items-center gap-1.5">
              <CustomIcon
                width={16}
                height={16}
                type={"Icon-Template"}
                className="text-grey-400"
              />
              <span className="text-500-14">Convertir en modèle de tâche (copie)</span>
            </div>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onClick={() => onDeleteSelectedTasks(task.id)}>
          <div className="flex items-center gap-1.5">
            <CustomIcon type="Icon-delete-garbage" className="" />
            <span className="text-500-14">Supprimer</span>
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

interface TaskListRowBottomBlockProps {
  label: string
  value: string
  iconBefore?: React.ReactNode
  valueDisabled?: boolean
  width?: number
  borderLeft?: boolean
}

function TaskListRowBottomBlock({
  label,
  value,
  iconBefore,
  valueDisabled,
  width,
  borderLeft,
}: TaskListRowBottomBlockProps) {
  return (
    <div className="flex items-center">
      {borderLeft && <div className="border border-[#E4E4E7] h-9 w-[1px]  mr-4 "></div>}
      <div className="h-9 flex flex-col gap-1" style={{ width }}>
        <div className="text-500-12 text-black-dark">{label}</div>
        <div className="text-500-14 text-black-dark flex items-center gap-1">
          {iconBefore}
          <span className={clsx(valueDisabled && "text-[#A1A1AA]")}>{value}</span>
        </div>
      </div>
    </div>
  )
}

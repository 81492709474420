/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable react/jsx-max-depth */

"use client"

import React, { useEffect } from "react"

import { Button } from "@/components/ui/button"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet"
import { DashboardTask } from "@/services/SupabaseTravoApi"

import {
  DropdownMenuTask,
  TaskBlock,
} from "@/app/[lang]/app/task/(components)/task-list/components/TaskListRow"
import { useCommonClientHooks } from "@/utils/custom-hooks"
import { cn } from "@/utils/utils"
import { VisuallyHidden } from "@radix-ui/react-visually-hidden"
import clsx from "clsx"
import { format } from "date-fns/format"
import { fr } from "date-fns/locale/fr"
import { parseISO } from "date-fns/parseISO"
import { AnimatePresence, motion } from "framer-motion"
import {
  ArrowLeft,
  CalendarDays,
  MapPin,
  Pencil,
  Printer,
  User,
  UsersRound,
} from "lucide-react"
import { Chip } from "../chip"
import { CustomIcon } from "../custom-icon"
import { SheetTaskAttachments } from "./sheet-task-attachments"


export function showPageSheetPanel(details: Omit<SheetPanel, "isOpen">) {
  const event = new CustomEvent("page-sidepanel", {
    detail: { ...details, isOpen: true },
  })
  window.dispatchEvent(event)
}

interface SheetPanel {
  isOpen: boolean
  type: "task"
  data: DashboardTask | null
  onClose: () => void
}

export const SheetPanel: React.FC = () => {
  const [data, setData] = React.useState<SheetPanel>({
    isOpen: false,
    onClose: () => { },
    data: null,
    type: "task",
  })

  useEffect(() => {
    window.addEventListener("page-sidepanel", (e: any) => {
      setData(e.detail)
    })

    return () => {
      window.removeEventListener("page-sidepanel", (e: any) => {
        setData(e.detail)
      })
    }
  }, [])

  const task = data.data

  return (
    <Sheet
      open={data.isOpen}
      defaultOpen={false}
      onOpenChange={(e) => {
        if (!e) {
          // data.onClose()
          setData({ ...data, isOpen: false })
        }
      }}
    >
      {data.type === "task" && task !== null && (
        <TaskSheet task={task} setData={setData} />
      )}
    </Sheet>
  )
}

function TaskSheet({
  task,
  setData,
}: {
  task: DashboardTask
  setData: React.Dispatch<React.SetStateAction<SheetPanel>>
}) {
  const [selectedSubtask, setSelectedSubtask] = React.useState<DashboardTask | null>(null)

  useEffect(() => {
    if (task.parent) {
      setSelectedSubtask(task)
    }
    return () => {
      setSelectedSubtask(null)
    }
  }, [task])

  const { routeTo } = useCommonClientHooks()

  const hasSubtask = task.subtasks.length > 0

  const isOpenable = hasSubtask || task.parent
  const taskToDisplay = task.parent ? task.parent : task

  let rightContent = null


  if (isOpenable) {
    rightContent = (
      <div className="w-[570px] h-full relative flex flex-col ">
        <div className="text-500-14 mt-4 mb-6">
          {taskToDisplay.subtasks.length} Sous-tâche
          {taskToDisplay.subtasks.length > 1 ? "s" : ""}
        </div>
        <div
          className="flex flex-col gap-6 overflow-y-auto custom-scrollbar !pb-0"
          style={{ height: "calc(100vh - 305px)" }}
        >
          {taskToDisplay.subtasks
            .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
            .map((subtask) => {
              return (
                <div
                  key={subtask.id}
                  className="h-[50px] py-1.5 flex items-center justify-between gap-2.5 border-l border-yellow-dark pl-2.5
                hover:bg-yellow-light cursor-pointer hover:border hover:border-yellow-dark"
                >
                  <div
                    className="flex-1 flex flex-col gap-2.5 "
                    onClick={() => {
                      setSelectedSubtask(subtask as unknown as DashboardTask)
                    }}
                  >
                    <div className="text-500-14 flex items-center justify-between">
                      <div className="text-400-14">{subtask.title}</div>
                      <div className="text-400-10 flex gap-1 items-center">
                        {subtask.status === "closed" ? (
                          <CustomIcon type="Icon-satus-closed" className="h-3 w-3" />
                        ) : (
                          <CustomIcon type="Icon-satus-open" className="h-3 w-3" />
                        )}
                        {subtask.status === "closed" ? "Clôturée" : "Ouverte"}
                      </div>
                    </div>
                    <TaskBlock task={subtask as unknown as DashboardTask} />
                  </div>
                  <div className="flex items-center justify-center">
                    <DropdownMenuTask
                      middle
                      task={subtask as unknown as DashboardTask}
                      items={[
                        {
                          label: "Modifier",
                          icon: <Pencil className="h-4 w-4" />,
                          onClick: () => {
                            setData((data) => ({ ...data, isOpen: false }))
                            if (task.parentId) {
                              routeTo("task", {
                                task: { id: task.id, parentId: task.parentId },
                              })
                            } else {
                              routeTo("task", { task: { id: task.id } })
                            }
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              )
            })}
        </div>
        <AnimatePresence>
          {selectedSubtask && (
            <motion.div
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "100%", opacity: 0 }}
              transition={{ type: "spring", damping: 25, stiffness: 200 }}
              className="absolute inset-0 bg-white "
            >
              <div className="h-full ">
                <div className="flex items-center h-[57px] gap-2.5 ">
                  <div
                    className="cursor-pointer border border-[#E4E4E7] flex items-center justify-center rounded-sm w-9 h-9"
                    onClick={() => setSelectedSubtask(null)}
                  >
                    <ArrowLeft className="w-4 h-4" />
                  </div>
                  <div className="border-l border-yellow-dark flex flex-col gap-1.5 pl-1.5">
                    <div className="text-400-14">{selectedSubtask.taskNumber}</div>
                    <div className="text-700-14">{selectedSubtask.title}</div>
                  </div>
                </div>

                <div className="h-[59px] flex justify-between pb-1.5 border-b border-[#D4D4D8] ">
                  {selectedSubtask.priority && selectedSubtask.priority !== "none" && (
                    <div className="w-[125px] flex gap-1 items-center shrink-0">
                      <Chip v2 priority={selectedSubtask.priority} />
                    </div>
                  )}
                  <div className=" shrink-0  w-[102px] text-black-dark flex flex-col ">
                    <div className="text-400-14">Création</div>
                    <div className="text-300-12">
                      {format(parseISO(task.createdAt), "dd/MM/yyyy", {
                        locale: fr,
                      })}
                    </div>
                    <div className="text-300-12">
                      {selectedSubtask.author.surname} {selectedSubtask.author.name}
                    </div>
                  </div>
                  <div className=" shrink-0 w-[102px] text-black-dark flex flex-col justify-center">
                    <div className="text-400-14">Dernière modif.</div>
                    <div className="text-300-12">
                      {selectedSubtask.editedAt
                        ? format(parseISO(selectedSubtask.editedAt), "dd/MM/yyyy", {
                          locale: fr,
                        })
                        : "-"}
                    </div>
                    {selectedSubtask.editor ? (
                      <div className="text-300-12">
                        {selectedSubtask.editor.surname} {selectedSubtask.editor.name}
                      </div>
                    ) : (
                      <div className="text-300-12">-</div>
                    )}
                  </div>
                  <div className=" shrink-0  w-[77px] text-black-dark  flex flex-col ">
                    <div className="text-400-14 flex gap-1 items-center">
                      {selectedSubtask.status === "closed" ? (
                        <CustomIcon type="Icon-satus-closed" className="h-4 w-4" />
                      ) : (
                        <CustomIcon type="Icon-satus-open" className="h-4 w-4" />
                      )}
                      {selectedSubtask.status === "closed" ? "Clôturée" : "Ouverte"}
                    </div>
                  </div>
                </div>
                <div
                  className="sheet-task-body flex-1  flex  gap-2 mt-3 overflow-y-auto custom-scrollbar !pb-0"
                  style={{ height: "calc(100vh - 360px)" }}
                >
                  <div className={cn("flex-1 mt-4 flex flex-col gap-8 ")}>
                    <SheetBodyTaskItemWithPlanned task={selectedSubtask} />
                    <SheetBodyTaskItemWithTeamsServices task={selectedSubtask} />
                    <SheetBodyTaskItemWithAssignations task={selectedSubtask} />
                    <SheetBodyTaskItemWithLocations task={selectedSubtask} />
                    <SheetBodyTaskItem
                      title="Description"
                      value={selectedSubtask.description}
                      emptyText="Pas de description"
                    />
                    <SheetBodyTaskItem
                      title="Remarques"
                      value={selectedSubtask.remarks}
                      emptyText="Pas de remarque"
                    />
                    <SheetTaskAttachments taskId={selectedSubtask.id} />
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    )
  }

  return (
    <SheetContent
      className={cn(
        " flex flex-col",
        isOpenable ? "sm:max-w-[1200px]" : "sm:max-w-[586px]",
      )}
    >
      <VisuallyHidden>
        <SheetHeader>
          <SheetTitle>Are you absolutely sure?</SheetTitle>
          <SheetDescription>
            This action cannot be undone. This will permanently delete your account and
            remove your data from our servers.
          </SheetDescription>
        </SheetHeader>
      </VisuallyHidden>
      <div className="mx-3 sheet-frame-1993 h-full">
        <div className="sheet-task-body m-3 h-full flex flex-col">
          <div className="sheet-task-header">
            <div className="sheet-task-header-main flex gap-2 items-center h-12">
              <div className="w-[125px] flex flex-col gap-1 items-center">
                <div className="text-400-14 text-black-dark">
                  {taskToDisplay.taskNumber}
                </div>
                {taskToDisplay.priority && taskToDisplay.priority !== "none" && (
                  <Chip v2 priority={taskToDisplay.priority} />
                )}
              </div>
              <div
                className="flex-1 text-700-16 line-clamp-2"
                title={taskToDisplay.title}
              >
                {taskToDisplay.title}
              </div>
            </div>
            <div className={cn("sheet-task-header-secondary flex  mt-4 ")}>
              <div className=" shrink-0 mr-[35px] w-[102px] text-black-dark">
                <div className="text-400-14">Création</div>
                <div className="text-300-12">
                  {format(parseISO(task.createdAt), "dd/MM/yyyy", { locale: fr })}
                </div>
                <div className="text-300-12">
                  {taskToDisplay.author.surname} {taskToDisplay.author.name}
                </div>
              </div>
              <div className=" shrink-0 mr-[35px] w-[102px] text-black-dark">
                <div className="text-400-14">Dernière modif.</div>
                <div className="text-300-12">
                  {taskToDisplay.editedAt
                    ? format(parseISO(taskToDisplay.editedAt), "dd/MM/yyyy", {
                      locale: fr,
                    })
                    : "-"}
                </div>
                {taskToDisplay.editor ? (
                  <div className="text-300-12">
                    {taskToDisplay.editor.surname} {taskToDisplay.editor.name}
                  </div>
                ) : (
                  <div className="text-300-12">-</div>
                )}
              </div>
              <div className=" shrink-0 mr-[35px] w-[93px] text-black-dark">
                <div className="text-400-14 flex gap-1 items-center">
                  <Printer className="w-4 h-4" />
                  Impression
                </div>
                <div className="text-300-12">
                  {taskToDisplay.printedAt
                    ? format(parseISO(taskToDisplay.printedAt), "dd/MM/yyyy", {
                      locale: fr,
                    })
                    : "-"}
                </div>
              </div>
              <div className=" shrink-0 mr-[35px] w-[77px] text-black-dark ">
                <div className="text-400-14 flex gap-1 items-center">
                  {taskToDisplay.status === "closed" ? (
                    <CustomIcon type="Icon-satus-closed" className="h-4 w-4" />
                  ) : (
                    <CustomIcon type="Icon-satus-open" className="h-4 w-4" />
                  )}
                  {taskToDisplay.status === "closed" ? "Clôturée" : "Ouverte"}
                </div>
              </div>
              <div className="flex-1 "></div>
              <div className="flex items-center justify-center">
                <DropdownMenuTask
                  middle
                  task={taskToDisplay}
                  items={[
                    {
                      label: "Modifier",
                      icon: <Pencil className="h-4 w-4" />,
                      onClick: () => {
                        setData((data) => ({ ...data, isOpen: false }))
                        routeTo("task", { task: { id: taskToDisplay.id } })
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="sheet-task-body flex-1  flex  gap-2 border-t border-[#D4D4D8] mt-2 pt-2">
            <div
              className={cn(
                "flex-1 mt-4 flex flex-col gap-8 overflow-y-auto custom-scrollbar !pb-0",
                isOpenable && "border-r border-[#D4D4D8]",
              )}
              style={{ height: "calc(100vh - 110px - 110px - 48px)" }}
            >
              <SheetBodyTaskItemWithPlanned task={taskToDisplay} />
              <SheetBodyTaskItemWithTeamsServices task={taskToDisplay} />
              <SheetBodyTaskItemWithAssignations task={taskToDisplay} />
              <SheetBodyTaskItemWithLocations task={taskToDisplay} />
              <SheetBodyTaskItem
                title="Description"
                value={taskToDisplay.description}
                emptyText="Pas de description"
              />
              <SheetBodyTaskItem
                title="Remarques"
                value={taskToDisplay.remarks}
                emptyText="Pas de remarque"
              />
              {taskToDisplay.fromRequestData ? (
                <SheetBodyTaskItem
                  title="Provenance de la demande"
                  value={[
                    taskToDisplay.fromRequestData?.requestingService === "" ||
                      !taskToDisplay.fromRequestData?.requestingService
                      ? "Pas de service"
                      : taskToDisplay.fromRequestData.requestingService,
                    taskToDisplay.fromRequestData?.fullName ?? "Pas de nom",
                    taskToDisplay.fromRequestData
                      ? format(
                        parseISO(taskToDisplay.fromRequestData.createdAt),
                        "dd/MM/yyyy",
                        {
                          locale: fr,
                        },
                      )
                      : "Pas de date",
                  ]}
                  emptyText="Pas de demande associée"
                />
              ) : (
                <SheetBodyTaskItem
                  title="Provenance de la demande"
                  value={null}
                  emptyText="Pas de demande"
                />
              )}
              {!taskToDisplay.contactPerson &&
                !taskToDisplay.contactPersonEmail &&
                !taskToDisplay.contactPersonPhone ? (
                <SheetBodyTaskItem
                  title="Personne de contact"
                  value={null}
                  emptyText="Pas de personne de contact"
                />
              ) : (
                <SheetBodyTaskItem
                  title="Personne de contact"
                  value={[
                    taskToDisplay.contactPerson ?? "Pas de nom",
                    taskToDisplay.contactPersonEmail ?? "Pas d'email",
                    taskToDisplay.contactPersonPhone ?? "Pas de téléphone",
                  ]}
                  emptyText="Pas de personne de contact"
                />
              )}

              <SheetTaskAttachments taskId={taskToDisplay.id} />
            </div>

            {rightContent}
          </div>
        </div>
      </div>

      <SheetFooter className="mx-3 h-[81px] pt-4 pb-6 flex justify-end gap-2 border-t border-[#D4D4D8]">
        {/* <Button variant="forecastOutline" type="submit">
          <CustomIcon type="Icon-Print" className="w-4 h-4 mr-2" />
          Imprimer
        </Button> */}

        <SheetClose
          asChild
          onClick={(_) => {
            // need to close the sheet before routing
            setData((data) => ({ ...data, isOpen: false }))
            if (task.parentId) {
              routeTo("task", {
                task: { id: task.id, parentId: task.parentId },
              })
            } else {
              routeTo("task", { task: { id: task.id } })
            }
          }}
        >
          <Button variant="forecast" type="submit">
            <Pencil className="w-5 h-5 mr-2 " />
            Modifier
          </Button>
        </SheetClose>
      </SheetFooter>
    </SheetContent>
  )
}

export function SheetBodyTaskItemWithPlanned({ task }: { task: DashboardTask }) {
  return (
    <div className={clsx("flex gap-4 ")}>
      <CalendarDays className="w-4 h-4" />
      {task.taskPlannedDates.length > 0 ? (
        <div className="flex flex-col gap-2">
          {task.taskPlannedDates.map((taskPlannedDate, index) => {
            // format date to Mercredi 28 mars 2024, 09:00
            const date = format(
              parseISO(taskPlannedDate.plannedDate),
              "EEEE dd MMMM yyyy",
              { locale: fr },
            )
            return (
              <div key={index} className="text-400-14 flex items-center">
                {task.taskPlannedDates.length > 1 && (
                  <div
                    className={cn(
                      "h-3 w-3 mr-4",
                      task.parent ? "bg-yellow-light" : "bg-blue-readable-blue",
                    )}
                  />
                )}
                {date}
              </div>
            )
          })}
        </div>
      ) : (
        <div className="text-[#A1A1AA] text-400-12 h-full flex items-center">
          Pas de date prévue
        </div>
      )}
    </div>
  )
}

export function SheetBodyTaskItemWithTeamsServices({
  task,
  minimalSpace,
}: {
  task: DashboardTask
  minimalSpace?: boolean
}) {
  const items = [...task.services, ...task.teams]

  if (minimalSpace && items.length === 0) {
    return null
  }

  if (items.length === 0) {
    return (
      <div className={clsx("flex gap-4 ")}>
        <UsersRound className="w-4 h-4" />
        <div className="text-[#A1A1AA] text-400-12 h-full flex items-center">
          Pas d&apos;équipe ou de service
        </div>
      </div>
    )
  }
  return (
    <div className={clsx("flex gap-4 ")}>
      <UsersRound className="w-4 h-4" />
      <div className="flex flex-wrap gap-2">
        {items.map((value, index) => {
          return <Chip key={index} variant={"forecast"} v2 label={value.name} />
        })}
      </div>
    </div>
  )
}

export function SheetBodyTaskItemWithAssignations({
  task,
  minimalSpace,
}: {
  task: DashboardTask
  minimalSpace?: boolean
}) {
  const items = task.assignations

  if (minimalSpace && items.length === 0) {
    return null
  }

  if (items.length === 0) {
    return (
      <div className={clsx("flex gap-4 ")}>
        <User className="w-4 h-4" />
        <div className="text-[#A1A1AA] text-400-12 h-full flex items-center">
          Pas d&apos;assignation
        </div>
      </div>
    )
  }

  return items.map((assignation, index) => {
    let date = null

    if (assignation.people[0]?.assignmentDate) {
      date = format(
        parseISO(assignation.people[0]?.assignmentDate),
        "EEEE dd MMMM yyyy",
        {
          locale: fr,
        },
      )
    }

    const hasDuration =
      assignation.people[0]?.durationHours !== null ||
      assignation.people[0]?.durationMinutes !== null
    const hh = (assignation.people[0]?.durationHours ?? 0).toString().padStart(2, "0")
    const mm = (assignation.people[0]?.durationMinutes ?? 0).toString().padStart(2, "0")
    const duration = `${hh}:${mm}`

    return (
      <div className="flex gap-4" key={index}>
        <User className="w-4 h-4 shrink-0" />
        <div className="flex flex-col gap-2 text-black-dark">
          <div
            className={`${date === null ? "text-[#A1A1AA] text-400-12" : "text-500-14"}`}
          >
            {date === null ? "Pas de date pour cette assignation" : date}
          </div>
          <div className="flex gap-2">
            <div className="text-300-14">
              <span
                className={`h-[22px] flex items-center ${!hasDuration && "text-[#A1A1AA] text-400-12"}`}
              >
                {duration}
              </span>
            </div>
            <div className="flex gap-2 flex-wrap">
              {assignation.people.map((p) => (
                <Chip
                  key={p.personId}
                  v2
                  variant={task.parentId ? "forecastYellow" : "forecast"}
                  label={`${p.person?.surname} ${p.person?.name}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  })
}

export function SheetBodyTaskItemWithLocations({ task }: { task: DashboardTask }) {
  const items = task.locations

  if (items.length === 0) {
    return (
      <div className={clsx("flex gap-4 ")}>
        <MapPin className="w-4 h-4" />
        <div className="text-[#A1A1AA] text-400-12 h-full flex items-center">
          Pas d&apos;adresse
        </div>
      </div>
    )
  }

  return (
    <div className="flex gap-4">
      <MapPin className="w-4 h-4 shrink-0" />
      <div className="flex flex-col gap-2">
        {task.locations.map((location) => {
          //@ts-ignore
          const address = location.address.string

          return (
            <div key={location.id} className="text-400-14 flex items-start">
              {task.locations.length > 1 && (
                <div
                  className={cn(
                    "shrink-0 h-3 w-3 mt-[1px] mr-4 rounded-full",
                    task.parent ? "bg-yellow-light" : "bg-blue-readable-blue",
                  )}
                />
              )}
              {address}
            </div>
          )
        })}
      </div>
    </div>
  )
}

function SheetBodyTaskItem({
  title,
  value,
  emptyText,
  customContent,
}: {
  title: string
  value?: string[] | string | null
  emptyText: string | null
  customContent?: React.ReactNode
}) {
  return (
    <div className="flex flex-col gap-2">
      {title && <div className="text-400-12 text-black-dark">{title}</div>}
      {customContent ? (
        <div className="text-400-14">{customContent}</div>
      ) : value ? (
        typeof value === "string" ? (
          <div className="text-400-14">{value}</div>
        ) : (
          <div className="">
            {value.map((v) => {
              return (
                <div
                  key={v}
                  className={clsx(
                    v.includes("Pas d") ? "text-400-12" : "text-400-14",
                    v.includes("Pas d") && "text-[#A1A1AA]",
                  )}
                >
                  {v}
                </div>
              )
            })}
          </div>
        )
      ) : (
        emptyText && <div className="text-400-12 text-[#A1A1AA]">{emptyText}</div>
      )}
    </div>
  )
}
